(function(main, $, undefined)
{
    var settings = {};

    var siteUrl,
        currentPage,
        tooltipTemplate,
        confirmationBoxDefer,
        settingsConfig,
        savedGraphs,
        battleOngoing,
        afterBattleQueue,
        pollIncomingChallengesCount,
        pollRefreshHeaderTeamInfoCount,
        isLoggedIn;

    // cache
    var $_modalLineup,
	    $_modalBattle,
        $_modalContent,
        $_modalSidebar,
	    $_headerLineup,
	    $_headerChakra,
	    $_headerTeam,
        $_leftcol,
        $_centercol,
        $_centercolOverlay,
        $_centercolLoading,
        $_messageBox,
        $_storyArc;

    main.init = function()
    {
        main.initHandlebars();

        this.settings = {
            'Device' : 'desktop',
            'CardSize' : {
                'small' : 'small',
                'medium' : 'medium'
            }
        };

        this.cacheInitialized = [];
        this.siteUrl = '/';
        this.currentPage = '/' + window.location.href.split('/').slice(3).join('/');
        this.settingsConfig = Cookies.getJSON('Config') || {};
        this.savedGraphs = [];
        this.battleOngoing = false;
        this.pollIncomingChallengesCount = 0;
        this.pollRefreshHeaderTeamInfoCount = 0;

        if (!jsData)
            jsData = {};

	    if (!jsonData)
		    jsonData = {};

        if (!jsLang)
            jsLang = {};

        if (typeof jsData['Config'] != 'undefined')
            $.extend(true, this.settings, jsData['Config']);

        // cache
        this.$_headerLineup = $('#lineup-content');
	    this.$_headerChakra = $('#header-chakra');
        this.$_headerTeam = $('#header-team');
        this.$_modalLineup = $('#modal-lineup');
	    this.$_modalBattle = $('#modal-battle');
        this.$_modalContent = $('#modal-content');
        this.$_modalSidebar = $('#modal-sidebar');
        this.$_leftcol = $('#left-col');
        this.$_rightcol = $('.l-right-col');
        this.$_centercol = $('.l-center-col');
	    this.$_centerContainer = $('#content');
        this.$_centercolOverlay = $('#inner-overlay');
        this.$_centercolLoading = main.$_centercol.find('.c-overlay');
        this.$_messageBox = this.$_centercol.find('.js-message-box');
        this.$_rightSidebarToggle = $('#right-col-toggle');

        if (typeof jsData['preloadImages'] !== 'undefined')
            $.preload(jsData['preloadImages']);

        if (typeof jsData['Lineup'] !== 'undefined')
            lineup.openLineupModal();

        if (typeof jsLang !== 'undefined')
            $.i18n.load(jsLang);

        this.uiEvents();

        // is logged in, poll refreshed data
        if (typeof jsData['isLoggedIn'] !== 'undefined' && jsData['isLoggedIn'])
        {
            main.pollIncomingChallenges();
            main.pollRefreshHeaderTeamInfo();

            isLoggedIn = true;
        }
    };

    main.pollIncomingChallenges = function()
    {
        if (main.pollIncomingChallengesCount >= 10)
            return false;

        setTimeout(function()
        {
            main.refreshChallengesIncoming();
            main.pollIncomingChallenges();
        }, 60000);

        ++main.pollIncomingChallengesCount;
    };

    main.refreshHeaderTeamInfoTimeLeft = function()
    {
        var d = new Date(),
            seconds = (d.getMinutes() * 60 + d.getSeconds()),
            goalTime = (60 * 5),
            timeLeft = (goalTime - seconds % goalTime) + 5;

        return (parseInt(timeLeft) * 1000);
    };

    main.pollRefreshHeaderTeamInfo = function()
    {
        if (main.pollRefreshHeaderTeamInfoCount >= 3)
            return false;

        setTimeout(function()
        {
            main.refreshHeaderTeamInfo();
            main.pollRefreshHeaderTeamInfo();
        }, main.refreshHeaderTeamInfoTimeLeft());

        ++main.pollRefreshHeaderTeamInfoCount;
    };

    main.storyArcSwitcher = function()
    {
        this.$_storyArc = $('#story-arc');

        if (!main.$_storyArc.is(':visible'))
            return false;

        setTimeout(function() {
            main.storyArcLoop();
        }, 4000);
    };

    main.storyArcLoop = function()
    {
        var active = main.$_storyArc.data('active'),
            $storyArcImg = main.$_storyArc.find('.bg img'),
            max = $storyArcImg.length,
            newActive = (max > active) ? active+1 : 1,
            $storyArcContainer = main.$_storyArc.find('.story-arc-switcher');

        if (max <= 1)
            return false;

        $storyArcImg.eq(active-1).fadeOut(500, function() {
            $storyArcImg.eq(newActive-1).fadeIn(500);
        });

        $storyArcContainer.eq(active-1).fadeOut(500, function() {
            $storyArcContainer.eq(newActive-1).fadeIn(500);
        });

        main.$_storyArc.data('active', newActive);

        setTimeout(function() {
            main.storyArcSwitcher();
        }, 6000);
    };

    main.showErrorMessage = function(error, options)
    {
        options = (typeof options != 'undefined') ? options : {};

        main.hideLoader(options);

        main.showMessageBox(error, 'html', options).then(function()
        {
            main.hideMessageBox(options);
        });
    };

    main.showMessageBox = function(data, templatePath, options)
    {
        data = (typeof data != 'undefined') ? data : {};
        templatePath = (typeof templatePath != 'undefined') ? templatePath : 'myteam/confirm-box';
        options = (typeof options != 'undefined') ? options : {};

        var $html;

        if (templatePath != 'html')
        {
            var defaultData = {
                'ConfirmText': $.i18n._('button_confirm'),
                'CancelText': $.i18n._('button_cancel'),
                'CloseText': $.i18n._('button_close')
            };

            data = $.extend(defaultData, data);

            // fetch any selection box templates
            if (typeof data['Selection'] !== 'undefined')
            {
            	var selectionTemplate = Handlebars.getTemplate(data['Selection']['Path']);

            	data['SelectionHtml'] = selectionTemplate(data['Selection']['Data']);
            }

            var template = Handlebars.getTemplate(templatePath);

            var html = template(data);
            $html = $($.parseHTML(html));
        }
        else
        {
            $html = data;
        }

	    var scrollTop = $(window).scrollTop();
	    var viewportCenter = (Math.round($(window).height() / 2));
	    var centerColOffset = 0;

	    if ($(window).width() > $(window).height())
	    {
		    viewportCenter /= 2;

		    centerColOffset = (this.$_centercol.offset().top - $(window).scrollTop());

		    if (centerColOffset < 0)
			    centerColOffset = 0;

		    centerColOffset = (this.$_centercol.offset().top - centerColOffset);
	    }

        var offsetTop = Math.round(scrollTop + viewportCenter - centerColOffset);

        main.confirmationBoxDefer = $.Deferred();

        if (options['sourceElement'])
        {
            $('#' + options['sourceElement']).addClass('-c-show-message').find('.js-message-box').html($html);
        }
        else
        {
            main.$_centercol.addClass('-c-show-message')
                .find('.js-message-box').html($html)
                .css('top', (offsetTop + 'px'));
        }


        return main.confirmationBoxDefer.promise();
    };

    main.showLoader = function(loaderClass)
    {
        loaderClass = typeof loaderClass !== 'undefined' ? ' -loader-' + loaderClass : '';

        var offsetTop = this.$_centercol.offset().top + $(window).scrollTop();

        if (offsetTop > 300)
            offsetTop -= 90;

        main.$_centercolLoading.addClass('-c-active').find('.c-overlay__loader').css('top', offsetTop);

        setTimeout(function() {
	        main.$_centercolLoading.addClass(loaderClass);
        }, 1);
    };

    main.hideLoader = function(options)
    {
        options = (typeof options != 'undefined') ? options : {};

        /*
        if (typeof options['sourceElement'] != 'undefined')
            $('#' + options['sourceElement']).find('.overlay-loading').removeClass('active');
        else
        */

	    main.$_centercolLoading.removeClass('-c-active');
    };

    main.hideMessageBox = function(options)
    {
        options = (typeof options != 'undefined') ? options : {};

        if (typeof options['sourceElement'] != 'undefined')
        {
            var $_sourceElement = $('#' + options['sourceElement']);

            $_sourceElement.removeClass('-c-show-message').find('.js-message-box').html('');
            $_sourceElement.find('.c-overlay').removeClass('-c-active');

            if (options['sourceElement'] === 'modal-battle') {
            	battle.closeBattleModal();
            }
        }
        else
        {
        	main.$_centercol.removeClass('-c-show-message').find('.js-message-box').html('');
        }
    };

    main.getTooltipLoaderColor = function(div, tooltipClass)
    {
        tooltipClass = typeof tooltipClass !== 'undefined' ? tooltipClass : '';

        var colorClass = 'h-item-default-1';

        if (div.attr('data-tt-color'))
            colorClass = div.attr('data-tt-color');
        else if (div.attr('class'))
        {
            var foundClass = $.grep(div.attr('class').split(' '), function(v, i) {
                return v.indexOf('h-item-') === 0;
            }).join(' ');

            if (foundClass)
                colorClass = foundClass;
        }

        var data = {
            'Class' : tooltipClass,
            'Color' : colorClass
        };

        return main.getTooltipLoader(data);
    };

    main.getTooltipLoader = function(data)
    {
    	var tooltipTemplate = (data['Class'] === 'help') ? '-help' : '';

        var template = Handlebars.getTemplate('general/tooltip-loader' + tooltipTemplate),
            html = template(data);

        return $($.parseHTML(html));
    };

    main.insertCss = function(file)
    {
    	if (typeof file === 'undefined')
    		return false;

    	var filePath = '/build/css/akatsuki-dawn/' + file + '.css';

	    // load forge css if not already active
	    if ($('link[href="' + filePath + '"]').length === 0) {
		    $('<link rel="stylesheet" href="' + filePath + '">').insertBefore('#site-container');
	    }
    };

    main.uiSidebarClan = function()
    {

    };

    main.uiSidebarAchievements = function()
    {

    };

    main.uiSidebarNews = function()
    {

    };

	main.uiSidebarNotes = function()
	{
		main.insertCss('forge');
	};

    main.uiSidebarFriends = function()
    {
        if ('sidebarFriends' in this.cacheInitialized)
            return true;

        this.cacheInitialized['sidebarFriends'] = true;

        main.$_modalSidebar.on('click', '.js-friends-sort .c-filter-button', clickSortButton);
        main.$_modalSidebar.on('click', '#friends-search', clickSearchFriendsButton);
        main.$_modalSidebar.on('click', '#friends-list .js-manage-friend', clickAddFriendButton);

        main.$_modalSidebar.on('keypress', 'input', function(e)
        {
            if (e.which == 13)
            {
                clickSearchFriendsButton();

                return false;
            }
        });


        function clickAddFriendButton()
        {
            var $_this = $(this),
                $_container = $_this.closest('.js-friend'),
                teamId = $_container.find('.js-challenge-team').data('teamid');

            var sendData = {
                'TeamID' : teamId,
                'Add' : $(this).hasClass('-color-yes') ? 1 : 0
            };

            main.sidebarShowLoader();

            $.ajaxHandler({
                url: '/ajax/sidebarFriendsEdit',
                type: 'POST',
                data: sendData,
                cache: false
            }, {
                sourceElement: 'modal-sidebar'
            }).done(function(data)
            {
                if (!sendData['Add'])
                    $_container.hide(500);
                else
                    $_this.addClass('-color-no').removeClass('-color-yes');

                main.sidebarHideLoader();
            });
        }

        function clickSearchFriendsButton()
        {
            if ($(this).hasClass('-color-cancel'))
                return false;

            var sendData = {
                'Teamname' : $('#friends-search-teamname').val()
            };

            main.sidebarShowLoader();

            $.ajaxHandler({
                url: '/ajax/sidebarFriendsSearch',
                type: 'POST',
                data: sendData,
                cache: false
            }, {
                sourceElement: 'modal-sidebar'
            }).done(function(data)
            {
                main.$_modalSidebar.find('#friends-list').html(data);

                // reset sorting
                main.$_modalSidebar.find('.js-friends-sort .c-filter-button[data-sort="online"]').addClass('-c-sel').siblings().removeClass('-c-sel');

                main.sidebarHideLoader();
            });
        }

        function clickSortButton()
        {
            var $_this = $(this),
                sort = $_this.data('sort'),
                selector = {};

            $_this.addClass('-c-sel').siblings().removeClass('-c-sel');

            if (sort == 'online')
                selector = { data: 'online' };
            else if (sort == 'user')
                selector = { selector: '.c-user-box__user', order: 'asc' };
            else if (sort == 'team')
                selector = { selector: '.c-user-box-team__name', order: 'asc' };
            else if (sort == 'rank')
                selector = { data: 'rank' };
            else if (sort == 'rating')
                selector = { data: 'rating' };

	        // place missing last
	        selector = $.extend(true, {
		        place: 'start',
		        order: 'desc'
	        }, selector);

            tinysort(main.$_modalSidebar.find('#friends-list .js-friend'), selector);
        }
    };

    main.uiEvents = function()
    {
        var $_rightcolChallenges = main.$_rightcol.find('.challenges');

	    $(document.body).on('mouseover', '.js-item-tooltip', showTooltipItem);
	    $(document.body).on('mouseover', '.js-team-tooltip', showTooltipTeam);
	    $('#tooltips,#stripped-container').on('click', '.js-copy-link', clickTooltipCopyLink);
        $(document.body).on('click', '.js-challenge-team', clickFightButton);
        main.$_headerLineup.on('click', '.c-card .c-levelup', clickLineupLevelBox);
        main.$_headerLineup.on('click', '.c-card', clickLineupCard);
        main.$_leftcol.find('#left-sidebar-menu .left-menu__item').on('click', clickSidebarMenuItem);
        $('.js-message-box').on('click', '.c-button', clickMessageBoxButton); // main.$_centercol.find
        $('#lineup-edit').on('click', clickLineupButton);
	    main.$_rightSidebarToggle.on('click', clickRightSidebarOpen);
        main.$_rightcol.find('.js-hide-right-sidebar').on('click', clickRightSidebarClose);
        main.$_rightcol.find('.chat-toggle').on('click', clickChatToggle);
        $(document.body).on('click', '.js-replay', clickRightSidebarReplay);
        $('#achievements-container .js-achievement-popup').on('click', clickAchievementBox);
	    main.$_centercol.on('click', '.js-goto-world', clickGotoWorld);
	    main.$_modalBattle.on('click', '.js-goto-world', clickModalGotoWorld);

        $('#logo').on('click', function()
        {
            window.location = main.siteUrl;
        });

        $('#user-right-toggle').on('click', clickShowUserInfoButton);
        $('.menu').find('li a').on('click', clickMenuButton);


        $(document.body).on('keypress', function(e)
        {
            // make enter trigger event buttons on confirmation modal boxes
            if (e.which === 13)
            {
                if (main.$_messageBox.is(':visible'))
                {
                    main.$_messageBox.find('.c-button:last').trigger('click');

                    e.preventDefault();
                }
            }
        });

	    // click outside to close sidebar modal etc
	    $(document.body).on('click', clickOutsideElement);

	    // close message boxes by clicking on the overlay outside
	    $('.c-overlay').on('click', clickOutsideMessageBox);


	    function clickTooltipCopyLink()
	    {
	    	var $_this = $(this),
			    path = $_this.attr('data-url');

	    	var url = window.location.origin + '/tooltip' + path;

	    	// copy to clipboard
		    var dummy = document.createElement('input');

		    document.body.appendChild(dummy);
		    dummy.setAttribute('value', url);
		    dummy.select();
		    document.execCommand('copy');
		    document.body.removeChild(dummy);
	    }

	    function clickAchievementBox()
        {
            $(this).fadeOut(500);

            if (Cookies.get('Temp_AchievementsRead'))
                return false;

            // cookie expires in ~1 hour
            main.setCookie('Temp_AchievementsRead', 1, { expires: 0.05 });
        }

        function clickChatToggle()
        {
            var $_this = $(this);

            var sendData = {
                'Disabled' : $_this.closest('.chat').hasClass('disabled') ? 0 : 1
            };

            $.ajaxHandler({
                url: '/ajax/toggleChat',
                data: sendData,
                type: 'POST'
            }).done(function(data)
            {
                location.reload();
            });
        }

        function clickRightSidebarReplay()
        {
            var $_this = $(this);

            main.loadBattleReplay($_this.data('replayid'));

            $_this.closest('.js-challenge-container').removeClass('-status-new');
        }

        function clickRightSidebarClose()
        {
            main.$_rightcol.removeClass('-c-expanded');
        }

        function clickRightSidebarOpen()
        {
            var $_this = $(this);

            $_this.removeClass('-c-new');

            main.$_rightcol.toggleClass('-c-expanded');

            var timeStamp = Math.round(new Date().getTime() / 1000);

            main.setCookie('Challenges_LastSeen', timeStamp);
        }

        function clickOutsideElement(e)
        {
	        /*
	        // remove click event handler if touchend type, to prevent firing twice
            if (e.type == 'touchend') {
                $(this).off('click');
            }
	        */

	        // close sidebar modal
            if (main.$_modalSidebar.is(':visible') && !main.$_modalLineup.is(':visible') && !main.$_modalBattle.is(':visible'))
            {
                if (!$(e.target).closest('#modal-sidebar').length)
                {
                    main.closeSidebar();

	                return true;
                }
            }
        }

	    function clickOutsideMessageBox(e)
	    {
		    /*
		    // remove click event handler if touchend type, to prevent firing twice
		    if (e.type == 'touchend') {
			    $(this).off('click');
		    }
		    */

		    if ($(this).siblings('.js-message-box').is(':visible'))
		    {
			    var $_msgBox = $(this).siblings('.js-message-box');

			    // check for first visible close button
			    if ($_msgBox.find('.c-button.-color-no').is(':visible')) {
				    $_msgBox.find('.c-button.-color-no:visible:first').trigger('click');
			    }

			    // check for last visiable green button
			    else if ($_msgBox.find('.c-button.-color-yes').is(':visible')) {
				    $_msgBox.find('.c-button.-color-yes:visible:last').trigger('click');
			    }

			    return true;
		    }
	    }

        function clickShowUserInfoButton()
        {
            $('header').toggleClass('-c-user-open');
        }

        function clickFightButton()
        {
            var $_this = $(this),
                $_closestModal = $_this.closest('.js-modal'),
                handlerObj = {};

            if ($_this.hasClass('-color-cancel'))
                return false;

            // check if we send challenge from within a modal
            if ($_closestModal.length > 0)
            {
                handlerObj = {
                    sourceElement: $_closestModal.attr('id')
                };
            }

            var sendData = {
                'TeamID' : $_this.data('teamid')
            };

            // disable challenges while loading/msg, mainly for sidebar
	        // TODO: needs double checking
            if (main.$_centercolLoading.css('opacity') == '1')
                return false;

            if (typeof jsData['Arena_Settings'] != 'undefined' && typeof jsData['Arena_Settings']['SkipBattleAnim'] != 'undefined' && jsData['Arena_Settings']['SkipBattleAnim'] == 1)
                sendData['SkipBattle'] = true;

            //main.battleOngoing = true;

            main.showLoader();

            var ajaxTime = Date.now();

            $.ajaxHandler({
                url: '/ajax/challengeTeam',
                type: 'POST',
                data: sendData,
                dataType: 'json'
            }, handlerObj).done(function(data)
            {
                main.hideLoader();
                main.updateHeaderTeamInfo(data['mission']['Cost']);

                // reset resend buttons for this team
                if ($_this.hasClass('-icon-challenge-return'))
                {
                    $('.js-challenge-team.-icon-challenge-return[data-teamid="' + sendData['TeamID'] + '"]').each(function() {
                        $(this).removeClass('-icon-challenge-return').addClass('-icon-challenge').find('span').html($.i18n._('button_fight'));
                    });
                }

                main.afterBattleQueue = $.Deferred();
                main.afterBattleQueue.done(function()
                {
                    main.$_rightcol.find('#challenges-outgoing').prepend(data['challengeBox']);
                });

                // match was played
                $(document).trigger('challengeSent');

	            // update bonus activity number
	            if (data['mission']['BonusActivity'] && jsData['curPage'] == 'arena')
	            {
		            $('#bonus-activity').html(data['mission']['BonusActivity']);
	            }

	            // skip battle window
                if (sendData['SkipBattle'])
                {
                    main.updateHeaderTeamInfo(data['mission']['HeaderRewards']);
                    main.headerLineupExp(data['mission']['HeaderLineupExp']);

                    main.afterBattleQueue.resolve();

                    return false;
                }

                if (typeof battle === 'undefined')
                	return false;

	            battle.showLoader();

	            main.$_centercolOverlay.show();
	            main.$_modalBattle.find('.js-battle-inner').html('');
	            main.$_modalBattle.show(1).addClass('-c-slide-down  js-modal-visible');

	            main.scrollToModal();
	            main.$_modalBattle.find('.js-battle-inner').html(data['content']);

	            var totalTime = Date.now() - ajaxTime;
	            var delayTime = (totalTime < 600) ? 600 - totalTime : 0;

	            battle.battleStartDelay = delayTime;
	            battle.initiateBattle(data['mission']);

            }).fail(function()
            {
                if ($_this.hasClass('c-button -color-yes')) {
                	$_this.removeClass('-color-yes').addClass('-color-cancel');
                }
            });
        }

        function clickMenuButton()
        {
            var $this = $(this);

	        // only load via ajax if not on index and not switching page
            if ($this.parent().data('page') == jsData['curPage'] && jsData['subPage'] != 'index')
            {
                var linkData = {
                    url: $this.attr('href')
                };

                main.loadPage(linkData);

                return false;
            }
        }

	    function clickGotoWorld()
	    {
		    var $this = $(this),
			    url = $this.data('url');

		    if ($this.hasClass('-color-cancel'))
			    return false;

		    var linkData = {
			    url: url
		    };

		    if ($this.data('external'))
		    {
			    window.location = url;

			    return false;
		    }

		    if ($this.data('subpage'))
			    linkData['subpage'] = $this.data('subpage');

		    if (url.indexOf('/mission/') >= 0)
		    {
			    main.scrollToModal();

			    world.loadMission(linkData);
		    }
		    else
		    {
		    	main.loadPage(linkData);
		    }

		    return false;
	    }

	    function clickModalGotoWorld()
	    {
		    var $this = $(this),
			    url = $this.data('url') || $this.data('href');

		    if (!$this.hasClass('js-goto-mission')) {
		    	battle.closeBattleModal();
		    }

		    if ($this.data('refresh') || url) // || (main.currentPage != url))
		    {
			    if (!url)
				    url = main.currentPage;

			    //HistoryManager.goBack();

			    var linkData = {
				    url: url
			    };

			    if ($this.data('refresh'))
				    linkData['scrollTo'] = 'NewSection';

			    if ($this.data('multi'))
				    linkData['multi'] = true;

			    if (url.indexOf('/mission/') >= 0)
				    world.loadMission(linkData);
			    else
				    main.loadPage(linkData);
		    }
		    else
		    {
			    var historyBack = ($this.data('historyback') || -1);

			    HistoryManager.goBack(historyBack);

			    world.afterMissionQueue.resolve();
		    }

		    return false;
	    }

        function clickLineupLevelBox(e)
        {
            e.stopPropagation();

            $(this).addClass('-c-unset').fadeOut(500, function() {
                $(this).remove();
            });
        }

        function clickLineupCard()
        {
            if (lineup.lineupModalOpen)
                return false;

            var $_this = $(this),
                TN_ID = $_this.attr('data-tnid');

            if (!TN_ID)
                return false;

            if (jsData['curPage'] == 'myteam')
                myteam.$_quickmenu.find('.c-card[data-tnid="' + TN_ID + '"]').trigger('click');
            else
                location.href = '/myteam/ninja/' + TN_ID;

            return false;
        }

        function clickSidebarMenuItem(e)
        {
            var $_this = $(this);

	        if (!$_this.data('url'))
		        return;

            if ($_this.hasClass('-c-sel'))
            {
                main.closeSidebar();

                return false;
            }

            window['main']['ui' + capitalizeFirstLetter($_this.data('url'))]();

            $_this.addClass('-c-sel').siblings().removeClass('-c-sel');

            main.$_centercolOverlay.show();
            main.$_modalSidebar.show();
            main.sidebarShowLoader();

            $.ajaxHandler({
                url: '/ajax/' + $_this.data('url'),
                type: 'POST'
            }, {
                sourceElement: 'modal-sidebar'
            }).done(function(data)
            {
                main.$_modalSidebar.find('.js-modal-content').html(data);
                main.sidebarHideLoader();
            });

            e.stopImmediatePropagation();
        }

        function clickMessageBoxButton(e)
        {
            var $_this = $(this),
                $_parent = $_this.closest('.c-overlay-message__inner'),
                $_parentMsg = $_parent.closest('.-c-show-message'),
                options = {};

            if ($_this.data('url'))
            {
                location.href = $_this.data('url');

                return false;
            }

            main.hideMessageBox({
                'sourceElement' : $_parentMsg.attr('id')
            });

            if (!$_this.attr('data-defer'))
                return false;

            // find any selected choices inside message box
            $_parent.find('.js-overlay-msg-selection').filter(function()
            {
                if ($(this).data('selected') != false) {
                    options[$(this).data('selector')] = $(this).data('selected');
                }
            });

            main.confirmationBoxDefer.resolve($_this.attr('data-defer'), options);
        }

        function clickLineupButton()
        {
            if (!main.$_modalLineup.is(':visible'))
            {
                lineup.openLineupModal();
            }
            else
            {
                lineup.cancelLineup();
            }
        }

	    function showTooltipTeam(event)
	    {
            if (!isLoggedIn)
                return false;
                
		    var $_this = $(this);

		    var positionOptions = {
			    my: 'center left',
			    at: 'top right',
			    target: $_this,
			    effect: false,
			    adjust: {
				    method: 'shift'
			    },
			    viewport: $(window),
			    container: $('#tooltips')
		    };

		    if ($_this.data('ttoptions')) {
			    positionOptions = main.getTooltipOptions($_this, positionOptions);
		    }

		    var $tooltipLoader = main.getTooltipLoaderColor($_this, '-big');

		    $(this).qtip({
			    style: { classes: 'qtip-dawn' },
			    overwrite: false,
			    content: {
				    text: function(event, api)
				    {
					    $.ajax({
						    url: '/tooltip/team/' + $_this.data('url')
					    }).then(function(content) {
						    api.set('content.text', content);
					    });

					    return $tooltipLoader;
				    }
			    },
			    show: {
				    event: event.type,
				    delay: 200,
				    ready: true,
				    effect: false
			    },
			    hide: {
				    fixed: true,
				    delay: 200,
				    effect: false
			    },
			    position: positionOptions
		    }, event);
	    }

        function showTooltipItem(event)
        {
            var $_this = $(this);

	        var positionOptions = {
		        my: 'left center',
		        at: 'right center',
		        target: $_this,
		        effect: false,
		        adjust: {
			        x: -3,
			        y: 30,
			        method: 'shift'
		        },
		        viewport: $(window),
		        container: $('#tooltips')
	        };

	        if ($_this.data('ttoptions')) {
		        positionOptions = main.getTooltipOptions($_this, positionOptions);
	        }

            $(this).qtip({
                style: { classes: 'qtip-dawn' },
                overwrite: false,
                content: {
                    text: function(event, api)
                    {
                        var $tooltipLoader = main.getTooltipLoaderColor($_this);

                        $.ajax({
                            url: $(this).attr('data-url')
                        }).then(function(content) {
							api.set('content.text', content);
                        });

                        return $tooltipLoader;
                    }
                },
                show: {
                    event: event.type,
                    delay: 200,
                    ready: true,
                    effect: false
                },
                hide: {
                    fixed: true,
                    delay: 200,
                    effect: false
                },
                position: positionOptions
            }, event);
        }
    };

    main.closeSidebar = function()
    {
        main.$_leftcol.find('#left-sidebar-menu .-c-sel').removeClass('-c-sel');

        main.sidebarHideLoader();

	    main.$_centercolOverlay.hide();

        main.$_modalSidebar.hide().removeClass('-c-show-message').find('.js-message-box').html('');
    };

    main.sidebarShowLoader = function()
    {
        main.$_modalSidebar.find('.c-overlay').addClass('-c-active');
    };

    main.sidebarHideLoader = function()
    {
        main.$_modalSidebar.find('.c-overlay').removeClass('-c-active');
    };

    main.scrollToModal = function()
    {
        var scrollPositionDefault = 0;//95;

        //if ($(document).scrollTop() > scrollPositionDefault)
            $('html, body').animate({ scrollTop: scrollPositionDefault });
    };

    main.scrollTo = function(scrollTo)
    {
        var scrollPosition = 0;

        if (scrollTo === 'NewSection')
            scrollPosition = (main.$_centerContainer.find('.content__outer:last').offset().top - 100);

        if (!scrollPosition)
            return false;

        $('html, body').animate({ scrollTop: scrollPosition });
    };

    main.scrollToDefault = function()
    {
        var scrollPositionDefault = main.$_centercol.offset().top;

        if ($(document).scrollTop() > scrollPositionDefault)
            $('html, body').animate({ scrollTop: scrollPositionDefault });
    };

    main.showNewAchievements = function()
    {
        if ('showNewAchievements' in this.cacheInitialized)
            return true;

        this.cacheInitialized['showNewAchievements'] = true;

        $('#achievements-container .js-achievement-popup').each(function(i,v)
        {
            $(this).delay(100 + (500 * i)).fadeIn(500);
        });
    };

    main.loadBattleReplay = function(replayId)
    {
        if (!replayId)
            return false;

        battle.showLoader();

	    main.$_centercolOverlay.show();
	    main.$_modalBattle.find('.js-battle-inner').html('');
	    main.$_modalBattle.show(1).addClass('-c-slide-down  js-modal-visible');

        HistoryManager.pushState(
            '/replays/watch/' + replayId,
            function() {},
            function() {
                battle.stopBattle();
                battle.exitBattle();
                battle.closeBattleModal();

                return {
                    'SkipBackFunctions' : true
                };
            }
        );

        var ajaxTime = Date.now();

        var handlerObj = {
	        sourceElement: 'modal-battle'
        };

        $.ajaxHandler({
            url: '/replays/watch/' + replayId,
            type: 'GET',
            dataType: 'json'
        }, handlerObj).done(function(data)
        {
            var totalTime = Date.now() - ajaxTime;
            var delayTime = (totalTime < 600) ? 600 - totalTime : 0; // TODO: set max 1000

            //main.loadPageEvents('battle');

            main.scrollToModal();

	        main.$_modalBattle.find('.js-battle-inner').html(data['content']);

            battle.battleStartDelay = delayTime;
            battle.initiateBattle(data['mission']);
        });
    };

    main.refreshChallengesIncoming = function()
    {
        $.ajaxHandler({
            url: '/ajax/refreshChallengesIncoming',
            type: 'GET'
        }).done(function(data)
        {
            if (!data)
                return false;

            var $_challengesIncoming = $('#challenges-incoming');

            $_challengesIncoming.find('.m-sb-challenges__row -date-fresh').removeClass('-date-fresh');//removeClass('new');

            $_challengesIncoming.prepend(data).find('.m-sb-challenges__row.-status-new').show(500);

            setTimeout(function() {
                $_challengesIncoming.find('.m-sb-challenges__row.-status-recent:not(.new):gt(2)').removeClass('-status-recent');
            }, 250);

            main.$_rightSidebarToggle.addClass('-c-new');

            // challenge received
            $(document).trigger('challengeReceived');
        });
    };

    main.refreshHeaderTeamInfo = function()
    {
        $.ajaxHandler({
            url: '/ajax/refreshHeaderTeam',
            type: 'POST',
            dataType: 'json'
        }).done(function(data)
        {
            main.updateHeaderTeamInfo(data);
        });
    };

    main.updateHeaderTeamInfo = function(data)
    {
        if (!data )
            return false;

	    if (!$.isPlainObject(data))
		    return false;

        $.each(data, function(itemName, value)
        {
            if (typeof value === 'object' && value !== null)
            {
                if (itemName == 'energy')
                    main.headerEnergyUpdate(value);

                if (itemName == 'chakra')
                    main.headerChakraUpdate(value);

                if (itemName == 'rank')
                    main.headerRankUpdate(value);
            }
            else
            {
                if (itemName === 'rating' || itemName === 'gold')
                    value = number_format(value);

	            main.$_headerTeam.find('.js-header-' + itemName).find('span').html(value);
            }
        });
    };

    main.headerRankUpdate = function(params)
    {
	    main.$_headerTeam.find('.js-header-rank').attr('class', 'header-team__details-rank  c-rank -rank-' + params['Rank'] + '  js-header-rank');
    };

    main.headerChakraUpdate = function(params)
    {
        if (params['Cur'])
            main.$_headerChakra.find('.js-lineup-chakra-cur').html(params['Cur']);

        if (params['Max'])
	        main.$_headerChakra.find('.js-lineup-chakra-max').html(params['Max']);
    };

    main.headerEnergyUpdate = function(energy)
    {
        $.each(energy, function(i, params)
        {
            var $_headerEnergy = main.$_headerTeam.find('.js-header-energy-' + params['Type'].toLowerCase());

            $_headerEnergy.find('.c-bar__fill').css({'width' : params['Percent'] + '%'});
            $_headerEnergy.find('.c-bar__text-cur').html(params['Energy']);
        });
    };

    main.headerLineupExp = function(params)
    {
        if (!params)
            return false;

        $.each(params, function(i, lineupNinja)
        {
            var $_headerLineupNinja = main.$_headerLineup.find('.c-card[data-tnid="' + lineupNinja['TN_ID'] + '"]');

            if (typeof lineupNinja['Levels'] != 'undefined')
            {
                $.each(lineupNinja['Levels'], function(index, levelData)
                {
                    $_headerLineupNinja.find('.c-exp__fill').animate({'width' : levelData['Percent'] + '%'}, 1500, function()
                    {
                        if (levelData['Percent'] == 100)
                        {
                            $(this).css({'width' : 0});
                            $_headerLineupNinja.find('.c-card__lvl-nr').html((levelData['Lvl'] + 1));
                        }
                    });
                });
            }

            if (typeof lineupNinja['LevelUp'] != 'undefined') {
                main.showLevelUp(lineupNinja['LevelUp']);
            }
        });
    };

    main.showLevelUp = function(params)
    {
        var template = Handlebars.getTemplate('general/level-up');

        var $_lineupNinja = main.$_headerLineup.find('.c-card[data-tnid="' + params['TN_ID'] + '"]'),
            barIndexNr = 0;

        var html = template(params);
        var $_html = $($.parseHTML(html));

        $_lineupNinja.append($_html.addClass('-c-visible'));

        setTimeout(function()
        {
            if (typeof params['Attributes'] != 'undefined')
            {
                $.each(params['Attributes'], function(barIndex, attribute)
                {
                    var $_bar = $_html.find('.c-bar').eq(barIndexNr);

                    $_bar.find('.c-bar__fill').css({'width': attribute['Percent'] + '%'});
                    ++barIndexNr;

                    setTimeout(function()
                    {
                        $_bar.find('.js-levelup-stat').show().find('b').countTo({
                            from: 0,
                            to: attribute['Growth'],
                            decimals: 1,
                            speed: 1000,
                            refreshInterval: 50
                        });
                    }, 500);
                });
            }

            setTimeout(function()
            {
	            $_html.find('.js-levelup-notification span').each(function(index)
                {
                    $(this).delay(index*500).queue(function(next)
                    {
                        $(this).show();
                        next();
                    });
                });
            }, 1500);

        }, 100);
    };

	main.showBloodlineLevelUp = function(params)
	{
		var template = Handlebars.getTemplate('general/level-up-bloodline');

		var $_lineupNinja = main.$_headerLineup.find('.c-card[data-tnid="' + params['TN_ID'] + '"]');

		if (!$_lineupNinja.length) {
			$_lineupNinja = main.$_headerLineup.find('.c-card:first');
		}

		var html = template(params);
		var $html = $($.parseHTML(html));

		$_lineupNinja.append($html.addClass('-c-visible'));
	};

    main.displayNinjaGraphs = function($div, options)
    {
	    if (!$div.length)
	    	return false;

        var graphId = 0,
            graphCount = main.savedGraphs.length;

        var defaultOptions = {};

        options = (typeof options != 'undefined') ? $.extend(defaultOptions, options) : defaultOptions;

        $div.each(function()
        {
            var datasets = JSON.parse($(this).attr('data-json'));

            if (datasets == null)
                return false;

            var data = {
                labels: false,
                datasets: datasets
            };

            var ctx = $(this).get(0).getContext('2d');

            if ($div.attr('id'))
                graphId = $div.attr('id');
            else
                graphId = graphCount;

            ++graphCount;

            main.savedGraphs[graphId] = new Chart(ctx).Radar(data, options);
        });
    };

    main.statsToGraphConvert = function(stats)
    {
        var statsData = {
            'value' : stats['Total'],
            'growth' : stats['Growth']
        };
        var statsDataNew = {
            'value' : {},
            'growth' : {}
        };

        $.each(statsData, function(catName, catStats)
        {
            var statPrefix = (catName == 'growth') ? '+' : '';

            $.each(catStats, function (stat, statValue) {
                statsDataNew[catName][stat.toLowerCase()] = statPrefix + statValue;
            });
        });

        if (typeof stats['Growth']['Pot'] != 'undefined')
            statsDataNew['growth']['pot'] = '(' + statsDataNew['growth']['pot'].substring(1) + ')';

        return statsDataNew;
    };

    main.updateNinjaGraph = function(graphId, datasets, stats)
    {
        if (typeof main.savedGraphs[graphId] == 'undefined')
            return false;

        $.each(datasets, function(datasetIndex, dataset)
        {
            if (typeof(dataset['data']) == "undefined")
                return true;

            $.each(dataset['data'], function(pointIndex, value)
            {
                main.savedGraphs[graphId].datasets[datasetIndex].points[pointIndex].value = value;
            });
        });

        main.savedGraphs[graphId].update();

        if (typeof stats != 'undefined')
        {
            var $_graphContainer = $('#' + graphId).parent();

            $.each(stats, function (catName, catStats)
            {
                $.each(catStats, function (stat, statValue)
                {
                    $_graphContainer.find('.-stat-' + stat).find('.c-ninja-graph-stat__' + catName).html(statValue);
                });
            });
        }
    };

	main.filterList = function(filterContainerId, list, options)
	{
		$.extend({
			'Limit' : false,
			'Multiple' : false,
			'NoneMeansAll' : false
		}, options);

		var selectedFilters = [],
			$listRows = $(list);

		$('#' + filterContainerId).find('.c-filter-button.-c-sel').each(function()
		{
			selectedFilters.push($(this).attr('data-filter'));
		});

		if (options['NoneMeansAll'] && selectedFilters.length === 0 || $.inArray('all', selectedFilters) > -1)
		{
			$listRows.show();
		}
		else
		{
			$listRows.hide();

			var i = 0,
				len = selectedFilters.length;

			if (!options['Multiple'])
			{
				var filters = '';

				for (i, len; i < len; ++i)
				{
					filters += '[data-filter~="' + selectedFilters[i] + '"]';
				}

				$.each($listRows, function(i,v)
				{
					$(this).filter(filters).show();
				});
			}
			else
			{
				for (i, len; i < len; ++i)
				{
					$listRows.filter('[data-filter~="' + selectedFilters[i] + '"]').show();
				}
			}
		}

		if (options['Limit'])
		{
			$listRows.filter(':visible').slice(options['Limit']).hide();
		}
	};

	main.setPreselectedListFilters = function(filterContainerId, list, options)
	{
		if (!window.location.hash)
			return false;

		var splitHash = window.location.hash.substr(1).split(',');

		$.each(splitHash, function(i, v) {
			$('#' + filterContainerId).find('.c-filter-button[data-filter="' + v + '"]').setFilterButtonSelection();
		});

		$.extend({}, options);

		main.filterList(filterContainerId, list, options);
	};

    main.hasPageEvent = function(subPage)
    {
        if (typeof window[jsData['curPage']] == 'undefined')
            return false;

        return (typeof window[jsData['curPage']]['sections'] != 'undefined' && subPage in window[jsData['curPage']]['sections']);
    };

    main.loadPageEvents = function(subPage)
    {
        if (main.hasPageEvent(subPage))
        {
            if (typeof window[jsData['curPage']][window[jsData['curPage']]['sections'][subPage]] == 'undefined')
                return false;

            window[jsData['curPage']][window[jsData['curPage']]['sections'][subPage]]();

            jsData['subPage'] = subPage;
        }
    };

    main.setStartingPushState = function()
    {
        var url = main.currentPage;

        var linkData = {
            url: url,
            subpage: jsData['subPage']
        };

        HistoryManager.replaceState(
            url,
            function()
            {
                main.loadPage(linkData);
            },
            function() {}
        );
    };

    main.setCookie = function(name, data, options)
    {
	    options = (typeof options != 'undefined') ? options : {};

        $.extend(options, {
            expires: 365
        });

        Cookies.set(name, data, options);
    };

    main.loadPage = function(linkData)
    {
        var url = linkData['url'];
	    var isStory = url.indexOf('/story/') !== -1;

        main.showLoader('spin');

        if (!linkData['subpage'] && jsData['curPage'] && window[jsData['curPage']])
        {
            var splitUrl = getPathFromUrl(url).split('/').slice(-2);

            for (var len = splitUrl.length - 1; len >= 0; len--)
            {
                // index page
                if (jsData['curPage'] === splitUrl[len])
                {
                    linkData['subpage'] = 'index';

                    break;
                }

                if (main.hasPageEvent(splitUrl[len]))
                {
                    linkData['subpage'] = splitUrl[len];

                    break;
                }
            }
        }

        var ajaxTime = Date.now();

        var defaultParams = {
            url: url,
            type: 'GET'
        };

        var params = $.extend({}, defaultParams, linkData);

        $.ajaxHandler(params).done(function(data)
        {
            HistoryManager.pushState(
                url,
                function()
                {
                    main.loadPage(linkData);
                },
                function() {}
            );

            var totalTime = Date.now() - ajaxTime;
            var delayTime = (totalTime < 200) ? 200 - totalTime : 0;

            main.currentPage = url;

            setTimeout(function()
            {
	            main.$_centerContainer.html(data);

                // set meta title
	            var $_metaData = main.$_centerContainer.find('#meta-data');

	            if ($_metaData.attr('data-title'))
	                document.title = $_metaData.attr('data-title');

                /*
                if (typeof testa !== 'undefined')
                    $.i18n.load(testa);
                */

                if (linkData['subpage'])
                    main.loadPageEvents(linkData['subpage']);

                if (linkData['scrollTo'])
                    main.scrollTo(linkData['scrollTo']);
                else
                    main.scrollToDefault();

                setTimeout(function()
                {
                    main.hideLoader();
                }, 100);

            }, delayTime);
        });
    };

    main.refreshQuickmenuControl = function()
    {
        var $_this = $(this),
            $quickmenu = $_this.closest('.m-quickmenu'),
            $innerOv = $quickmenu.find('.js-quickmenu-inner'),
            innerOvPosition = parseInt($innerOv.attr('data-pos')),
            movePixels = -400,
            cardAmount = $quickmenu.find('.c-card').length;

        if ($_this.hasClass('-side-right'))
            ++innerOvPosition;
        else
            --innerOvPosition;

        var movePixelsNew = innerOvPosition * movePixels;

        $innerOv.attr('data-pos', innerOvPosition).animate({
            left: movePixelsNew + 'px'
        }, 250, function()
        {
	        $quickmenu.find('.js-quickmenu-control').removeClass('-c-active');

            if (innerOvPosition > 0)
                $quickmenu.find('.-side-left').addClass('-c-active');

            if (innerOvPosition == 0 || ((innerOvPosition + 1) * 8 < cardAmount))
                $quickmenu.find('.-side-right').addClass('-c-active');
        });
    };

    main.getTrumbowygConfig = function()
    {
	    return {
		    svgPath: '/img/svg/trumbowyg/icons.svg',
		    btnsDef: {
			    align: {
				    dropdown: ['justifyLeft', 'justifyCenter', 'justifyRight'],
				    ico: 'justifyLeft'
			    }
		    },
		    btns: [
			    ['viewHTML'],
			    ['formatting', 'strong', 'em', 'removeformat'],
			    ['link', 'insertImage'],
			    ['unorderedList', 'orderedList', 'align'],
			    ['horizontalRule'],
			    ['fullscreen']
		    ],
		    semantic: false,
		    minimalLinks: true
	    };
    };

    main.initHandlebars = function()
    {
        Handlebars.registerHelper('i18n', function(str) {
            return ($.i18n != undefined ? $.i18n._(str) : str);
        });

        Handlebars.registerHelper('replace', function(str, params)
        {
            var args = [];

            for (var prop in params.hash) {
                args.push(params.hash[prop]);
            }

            return new Handlebars.SafeString(vsprintf(str, args));
        });

        Handlebars.registerHelper('sprintf', function(str, params)
        {
            var args = [],
                string = '';

            for (var prop in params.hash) {
                args[prop] = params.hash[prop];
            }

            try {
                string = sprintf(str, args);
            } catch(e) {
                string = '';
            }

            return new Handlebars.SafeString(string);
        });

        Handlebars.registerHelper('ifCond', function(v1, v2, options)
        {
            if (v1 === v2) {
                return options.fn(this);
            }

            return options.inverse(this);
        });

	    Handlebars.registerHelper('ifElse', function(variable, fallbackText)
	    {
	    	var string = (typeof variable !== 'undefined') ? variable : fallbackText;

		    return new Handlebars.SafeString(string);
	    });

		Handlebars.getTemplate = function (name)
		{
			// for development mode only; fetches the template on the fly via get and compiles
			if (Handlebars.templates === undefined || Handlebars.templates[name] === undefined)
			{
				$.ajax({
					url: '/src/js/templates/' + name + '.handlebars',
					success: function (data)
					{
						if (Handlebars.templates === undefined) {
							Handlebars.templates = {};
						}

						Handlebars.templates[name] = Handlebars.compile(data);
					},
					async: false
				});
			}

			return Handlebars.templates[name];
		};
    };

	main.getTooltipOptions = function($_this, positionOptions)
	{
		var optionId = $_this.data('ttoptions');

		var tooltipOptions = {
			2 : {
				my: 'top right',
				at: 'center left',
				adjust: {
					x: 0,
					y: -30,
					screen: true,
					method: 'shift'
				}
			},
			3 : {
				target: $_this.closest('.a-row'),
				my: 'top right',
				at: 'center left',
				adjust: {
					x: 0,
					y: -30,
					screen: true,
					method: 'shift'
				}
			}
		};

		if (typeof tooltipOptions[optionId] == 'undefined')
			return positionOptions;

		positionOptions = $.extend(true, positionOptions, tooltipOptions[optionId]);

		return positionOptions;
	};

	main.showTooltipRanks = function(event)
	{
		var $_this = $(this),
			rank = ($_this.attr('class').match(/-rank-\S+/g) || []).join(' ').split(/[-]+/).pop();

		var positionOptions = {
			my: 'left center',
			at: 'right center',
			target: $_this,
			effect: false,
			adjust: {
				x: 11,
				y: 50,
				method: 'shift'
			},
			viewport: $(window),
			container: $('#tooltips')
		};

		var $tooltipLoader = main.getTooltipLoaderColor($_this, 'help');

		$(this).qtip({
			style: { classes: 'qtip-dawn' },
			overwrite: false,
			content: {
				text: function(event, api)
				{
					$.ajax({
						url: '/tooltip/info/ranks?Rank=' + rank
					}).then(function(content) {
						api.set('content.text', content);
					});

					return $tooltipLoader;
				}
			},
			show: {
				event: event.type,
				delay: 100,
				ready: true,
				effect: false
			},
			hide: {
				fixed: true,
				delay: 100,
				effect: false
			},
			position: positionOptions
		}, event);
	};

	main.showTooltipAttributes = function(event)
	{
		var $_this = $(this);

		var positionOptions = {
			my: 'left center',
			at: 'right center',
			target: $_this,
			effect: false,
			adjust: {
				x: 11,
				y: 50,
				method: 'shift'
			},
			viewport: $(window),
			container: $('#tooltips')
		};

		var $tooltipLoader = main.getTooltipLoaderColor($_this, 'help');

		$(this).qtip({
			style: { classes: 'qtip-dawn' },
			overwrite: false,
			content: {
				text: function(event, api)
				{
					$.ajax({
						url: '/tooltip/info/attributes'
					}).then(function(content) {
						api.set('content.text', content);
					});

					return $tooltipLoader;
				}
			},
			show: {
				event: event.type,
				delay: 100,
				ready: true,
				effect: false
			},
			hide: {
				fixed: true,
				delay: 100,
				effect: false
			},
			position: positionOptions
		}, event);
	};

}(window.main = window.main || {}, jQuery));

$(function()
{
    main.init();
});
