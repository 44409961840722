(function(lineup, $, undefined)
{
    var initialized,
        lineupModalOpen,
        activeLineup,
        $activeLineupSaved,
        selectedCardId,
        selectingCard,
        chakraUsage,
        chakraMax,
        combos,
        activeComboNinjas,
        ninjasData,
        positions,
        positionPenalty;

    // cache
    var $_headerLineup,
        $_modalLineupOverlay,
        $_modalLineupCards;

    lineup.init = function()
    {
        this.lineupModalOpen = true;

        if (this.initialized)
            return true;

        // cache
        this.$_headerLineup = $('#lineup-content');
        this.$_modalLineupOverlay = main.$_modalLineup.find('.c-overlay');

        this.ui();
        this.uiCards();

        this.initialized = true;
        this.selectedCardId = false;
        this.chakraUsage = 0;
        this.combos = {};
        this.ninjasData = {};
        this.activeComboNinjas = {};

        this.positionPenaltyStaminaGrowth = 3;
        this.positionPenalty = {
            1 : {
                'Stamina' : 50,
                'Penalty' : 0.8
            },
            2 : {
                'Stamina' : 100,
                'Penalty' : 0.65
            },
            3 : {
                'Stamina' : 200,
                'Penalty' : 0.5
            }
        };

        this.positions = {
            1 : 1,
            2 : 1,
            3 : 1,
            4 : 2,
            5 : 2,
            6 : 3,
            7 : 4
        };
    };

    lineup.ui = function()
    {
        main.$_modalLineup.on('click', '#update-lineup', clickUpdateLineup);
        main.$_modalLineup.on('click', '.js-lineup-list .c-filter-button', clickLineupButton);
        lineup.$_headerLineup.on('click', '.c-card', clickLineupCard);


        function clickUpdateLineup()
        {
            lineup.updateLineup();
        }

        function clickLineupButton()
        {
            var $this = $(this),
                lineupId = $this.attr('data-lineup');

            $this.addClass('-c-sel').siblings().removeClass('-c-sel');

            lineup.loadLineup(lineupId);
        }

        function clickLineupCard()
        {
            if (!lineup.lineupModalOpen)
                return false;

            var $this = $(this),
                clickedCardId = $this.attr('data-tnid'),
                selectedCardIdSaved = lineup.selectedCardId,
                pos = ($this.index() + 1);

            // adding
            if (lineup.$_headerLineup.hasClass('-status-selectable'))
            {
                if (clickedCardId)
                {
                    lineup.deselectCard(clickedCardId);
                }

                var $selectedCard = main.$_modalLineup.find('.pm-lineup-card[data-tnid="' + selectedCardIdSaved + '"]');

                var $cardClone = $selectedCard.find('.c-card').clone().removeClass('-size-m').addClass('-size-s').attr('data-tnid', selectedCardIdSaved);

                //$cardClone.find('.pic img').attr('src', $cardClone.find('.pic img').attr('src').replace("/medium/", "/small/"));

                var regex = new RegExp("/(small|medium)/");

                $cardClone.find('.c-card__pic img').attr('src', function(i,e) {
                    return e.replace(regex, "/small/");
                });

                $this.replaceWith($cardClone);

                main.$_modalLineup.find('.pm-lineup-card[data-tnid="' + selectedCardIdSaved + '"]').removeClass('-status-selectable')
                    .find('.c-button').addClass('-color-no').removeClass('-color-yes').find('span')._t('lineup_remove');

                lineup.$_headerLineup.attr('class', '-status-editing');

                main.$_modalLineup.find('#lineup-cards').removeClass('-status-selecting');

                lineup.selectedCardId = false;

                lineup.updateChakraUsage($selectedCard.find('.c-card__chakra span').html());
                lineup.refreshCombos();
                lineup.setLineupOffPositioning();

                /*
                setTimeout(function() {
                    if (pos == 7)
                        lineup.setLineupOffPositioning();
                    else
                        lineup.setOffPositioning(selectedCardIdSaved, pos);
                }, 500);
                */
            }

            // removing
            else
            {
                if (!clickedCardId)
                    return false;

                lineup.deselectCard(clickedCardId);
            }
        }
    };

    lineup.uiCards = function()
    {
        // cache
        this.$_modalLineupCards = main.$_modalLineup.find('#lineup-cards');

        main.$_modalLineup.on('click', '#lineup-sort .c-filter-button', clickSortButton);
        main.$_modalLineup.on('click', '#lineup-filter .c-filter-button', clickFilterButton);

        // modal: select/deselect card
        main.$_modalLineup.on('click', '#lineup-cards .c-button', clickCardSelectButton);
	    main.$_modalLineup.on('click', '#lineup-extra-cards .c-button', clickExtraCardSelectButton);


        function clickSortButton()
        {
            var $_cards = main.$_modalLineup.find('.pm-lineup-card');

            var $this = $(this),
                sort = $this.data('sort'),
                selector = {};

            $this.addClass('-c-sel').siblings().removeClass('-c-sel');

            if (!$_cards.length)
                return false;

            if (sort == 'level')
                selector = { selector: '.c-card__lvl-nr', order: 'desc' };
            else if (sort == 'dmg')
                selector = { selector: '.c-card__dmg b', order: 'desc' };
            else if (sort == 'chakra')
                selector = { selector: '.c-card__chakra > span', order: 'desc' };

	        // place missing last
	        $.extend(selector, {
	        	place: 'start'
	        });

            tinysort($_cards, selector);

            lineup.updateCardSize();
        }

        function clickFilterButton()
        {
            var $this = $(this),
                filter = $this.data('filter');

            if (filter == 'all')
                $this.addClass('-c-sel').siblings().removeClass('-c-sel');
            else
            {
                if ($this.hasClass('-c-sel'))
                {
                    $this.removeClass('-c-sel');

                    if (!$this.siblings('.-c-sel').length)
                        $this.siblings('.c-filter-button').eq(0).addClass('-c-sel');
                }
                else
                    $this.addClass('-c-sel').siblings('.c-filter-button').eq(0).removeClass('-c-sel');
            }

            lineup.refreshFilters();
        }

        function clickCardSelectButton()
        {
            var $this = $(this),
                $cardContainer = $this.closest('.pm-lineup-card');

            if (lineup.selectedCardId !== false)
            {
                main.$_modalLineup.find('.pm-lineup-card[data-tnid="' + lineup.selectedCardId + '"]').removeClass('-status-selectable');

                lineup.$_headerLineup.attr('class', '-status-editing');

                main.$_modalLineup.find('#lineup-cards').removeClass('-status-selecting');

                lineup.selectedCardId = false;

                return true;
            }


            lineup.selectedCardId = $cardContainer.attr('data-tnid');

            // select
            if ($this.hasClass('-color-yes'))
            {
                $cardContainer.addClass('-status-selectable');

                var pos = $cardContainer.find('.c-card__pos').attr('class');
                pos = pos[pos.length -1];

                lineup.$_headerLineup.addClass('-status-selectable highlight-pos-' + pos);

                main.$_modalLineup.find('#lineup-cards').addClass('-status-selecting');
            }

            // deselect
            else
            {
                lineup.deselectCard(lineup.selectedCardId);
            }
        }

	    function clickExtraCardSelectButton()
	    {
		    var $_this = $(this),
			    $_parent = $_this.closest('.pm-lineup-card'),
			    active = $_this.hasClass('-color-yes') || false,
			    chakra = $_parent.find('.c-card__chakra span').html() || 0;

		    if (active) {
		    	chakra *= -1;
		    }

		    if (chakra !== 0) {
			    lineup.updateChakraUsage(chakra);
		    }

		    $_this.toggleClass('-color-yes -color-disabled');
	    }
    };

    lineup.refreshFilters = function()
    {
        var selectedFilters = [];

        main.$_modalLineup.find('#lineup-filter .c-filter-button.-c-sel').each(function()
        {
            selectedFilters.push($(this).index());
        });

        if ($.inArray(0, selectedFilters) > -1)
        {
            main.$_modalLineup.find('.pm-lineup-card').show();

            lineup.updateCardSize();

            return true;
        }

        main.$_modalLineup.find('.pm-lineup-card').hide().filter(function()
        {
            for (var i = 0, len = selectedFilters.length; i < len; ++i)
            {
                if ($(this).find('.-pos-' + selectedFilters[i]).length > 0)
                    return true;
            }

            return false;
        }).show();

        lineup.updateCardSize();
    };

    lineup.updateCardSize = function()
    {
        var containerSize = main.$_modalLineup.find('#lineup-cards').width(),
            firstRowCards = (containerSize > 900) ? 6 : 5;

        main.$_modalLineup.find('.pm-lineup-card.-size-s').removeClass('-size-s').addClass('-size-m').find('.c-card.-size-s').removeClass('-size-s').addClass('-size-m').find('.c-card__pic  img').attr('src', function(i,e) {
            return e.replace("/small/", "/medium/");
        });

        main.$_modalLineup.find('.pm-lineup-card:visible').slice(firstRowCards).removeClass('-size-m').addClass('-size-s').find('.c-card').removeClass('-size-m').addClass('-size-s').find('.c-card__pic  img').attr('src', function(i,e) {
            return e.replace("/medium/", "/small/");
        });
    };

    lineup.deselectCard = function(id)
    {
        var $cardInLineup = lineup.$_headerLineup.find('.c-card[data-tnid="' + id + '"]');

        if ($cardInLineup)
        {
        	var pos = $cardInLineup.find('.c-card__pos').attr('class');
	        pos = pos[pos.length -1];

            $cardInLineup.attr('class', 'c-card -c-empty -size-s').removeAttr('data-tnid data-ninjaid'); //attr({'data-id': ''});

	        var template = Handlebars.getTemplate('lineup/empty-card');

	        var html = template({
		        'Position' : pos,
		        'PositionName' : $.i18n._('lineup_position_' + pos)
	        });

	        $cardInLineup.html($.parseHTML(html));

            var $selectedCard = main.$_modalLineup.find('.pm-lineup-card[data-tnid="' + id + '"]');

            $selectedCard.find('.c-button').removeClass('-color-no').addClass('-color-yes').find('span')._t('lineup_select');

            lineup.selectedCardId = false;

            lineup.updateChakraUsage(parseInt($selectedCard.find('.c-card__chakra span').html()) * -1);
            lineup.refreshCombos();
            lineup.setLineupOffPositioning();

            //if ($cardInLineup.index() == 6)
            //    lineup.setLineupOffPositioning();
        }
    };

    lineup.refreshCombos = function()
    {
        var currentLineup = lineup.getLineupNinjaID();
        var activeComboNinjas = {};

        if (!$.isEmptyObject(lineup.combos))
        {
            main.$_modalLineup.find('#lineup-combos .c-item').addClass('h-item-disabled');

            var comboCompletionCur = 0,
                comboCompletionMax = 0;

            $.each(lineup.combos, function(comboId, compositions)
            {
                $.each(compositions, function(i, composition)
                {
                    comboCompletionCur = 0;
                    comboCompletionMax = composition.length;

                    $.each(composition, function(i, ninjaId)
                    {
                        if ($.inArray(ninjaId, currentLineup) > -1)
                            ++comboCompletionCur;
                    });

                    if (comboCompletionCur == comboCompletionMax)
                    {
                        main.$_modalLineup.find('#lineup-combos .c-item[data-id="' + comboId + '"]').removeClass('h-item-disabled');

                        if (!$.isEmptyObject(lineup.combosData) && typeof lineup.combosData[comboId] != 'undefined')
                        {
                            $.each(composition, function(i, ninjaId)
                            {
                                $.each(lineup.combosData[comboId], function(stat, val)
                                {
                                    if (typeof activeComboNinjas[ninjaId] == 'undefined')
                                        activeComboNinjas[ninjaId] = {};

                                    if (typeof activeComboNinjas[ninjaId][stat] != 'undefined')
                                        activeComboNinjas[ninjaId][stat] += val;
                                    else
                                        activeComboNinjas[ninjaId][stat] = val;
                                });

                            });
                        }

                        return false;
                    }
                });
            });
        }

        lineup.activeComboNinjas = activeComboNinjas;
    };

    lineup.setOffPositioning = function(tnid, pos)
    {
        if (!lineup.ninjasData[tnid])
            return false;

        var ninjaPos = lineup.ninjasData[tnid]['Position'],
            posType = lineup.positions[pos];

        // skip summon
        if (ninjaPos == 4)
            return false;

        // correct position
        if (ninjaPos == posType)
            return false;

        var ninjaStats = $.extend({
            'Sta' : 0,
            'OffPos' : 0
        }, lineup.ninjasData[tnid]['Stats']);

        var penaltyStaRequired = (lineup.positionPenalty[ninjaPos]['Stamina'] + lineup.positionPenaltyStaminaGrowth * lineup.ninjasData[tnid]['Level']),
            penaltyPercent = lineup.positionPenalty[posType]['Penalty'];

        // summon data
        var summonTN_ID = lineup.$_headerLineup.find('.c-card:eq(6)').attr('data-tnid');

        if (summonTN_ID && lineup.ninjasData[summonTN_ID]['Stats'])
        {
            $.each(lineup.ninjasData[summonTN_ID]['Stats'], function(stat, val) {
                ninjaStats[stat] += val;
            });
        }

        var ninjaId = lineup.$_headerLineup.find('.c-card[data-tnid="' + tnid + '"]').attr('data-ninjaid');

        if (lineup.activeComboNinjas[ninjaId])
        {
            $.each(lineup.activeComboNinjas[ninjaId], function(stat, val) {
                ninjaStats[stat] += val;
            });
        }

        var positionPenaltyStaEff = (ninjaStats['Sta'] / penaltyStaRequired);

        if (positionPenaltyStaEff >= 1)
            return false;

        penaltyPercent -= (penaltyPercent * (ninjaStats['OffPos'] / 100));

        if (penaltyPercent <= 0)
            return false;

        var positionPenaltyPercent = (penaltyPercent - (penaltyPercent * positionPenaltyStaEff));

        if (positionPenaltyPercent <= 0)
            return false;

        positionPenaltyPercent = Math.ceil(positionPenaltyPercent * 100);

        var $addOffPosDiv = $('<div class="c-card__offpos"><b>-' + positionPenaltyPercent + '%</b></div>');

        lineup.$_headerLineup.find('.c-card[data-tnid="' + tnid + '"]').find('.c-card__pic').append($addOffPosDiv);
    };

    lineup.setLineupOffPositioning = function()
    {
        lineup.$_headerLineup.find('.c-card__offpos').remove();

        lineup.$_headerLineup.find('.c-card').each(function(i, v)
        {
            var pos = (i + 1),
                tnid = $(v).attr('data-tnid');

            if (!tnid || pos == 7)
                return;

            lineup.setOffPositioning(tnid, pos);
        });
    };

    lineup.setChakraUsageHtml = function()
    {
        main.$_modalLineup.find('.pm-lineup-chakra-box__nr b').html(lineup.chakraUsage);

	    main.$_modalLineup.find('.pm-lineup-chakra-box__free b').html(lineup.chakraMax - lineup.chakraUsage);

        if (lineup.chakraUsage > lineup.chakraMax)
            main.$_modalLineup.find('.pm-lineup-chakra-box__nr').addClass('h-text-negative');
        else
            main.$_modalLineup.find('.pm-lineup-chakra-box__nr').removeClass('h-text-negative');
    };

    lineup.updateChakraUsage = function(chakraCost)
    {
        lineup.chakraUsage += parseInt(chakraCost);

        lineup.setChakraUsageHtml();
    };

    lineup.getLineupNinjaID = function()
    {
        var lineupArray = [];

        lineup.$_headerLineup.find('.c-card').each(function()
        {
            if ($(this).attr('data-ninjaid'))
                lineupArray.push(parseInt($(this).attr('data-ninjaid')));
        });

        return lineupArray;
    };

    lineup.getLineup = function()
    {
        var lineupArray = [];

        lineup.$_headerLineup.find('.c-card').each(function()
        {
            var id = $(this).attr('data-tnid') ? $(this).attr('data-tnid') : '';

            lineupArray.push(id);
        });

        return lineupArray;
    };

	lineup.getLineupExtra = function()
	{
		var lineupArray = [];

		main.$_modalLineup.find('#lineup-extra-cards .c-button.-color-yes').each(function()
		{
			var $_this = $(this),
				$_container = $_this.closest('.pm-lineup-card'),
				id = $_container.attr('data-tnid');

			if (!id)
				return;

			lineupArray.push(id);
		});

		return lineupArray;
	};

    lineup.updateLineup = function()
    {
        var sendData = {
            'lineupId' : lineup.activeLineup,
            'position' : lineup.getLineup(),
	        'extra' : lineup.getLineupExtra()
        };

        lineup.showLoader();

        $.ajaxHandler({
            url: '/myteam/lineup/update',
            type: 'POST',
            data: sendData,
            dataType: 'json',
            cache: false
        }, {
            sourceElement: 'modal-lineup'
        }).done(function(data)
        {
            lineup.$_headerLineup.attr('data-lineup', lineup.activeLineup);

            $('.js-lineup-chakra-cur').html(data['data']['chakra']);

	        main.$_modalLineup.find('#lineup-details').html(data['lineupTeamDetails']);

            // refresh world area to update difficulty estimation
            if (jsData['curPage'] == 'world' && (jsData['subPage'] == 'area' || jsData['subPage'] == 'village')) {
                main.loadPage({
                    'url' : main.currentPage
                });
            }

	        main.$_headerTeam.find('.js-team-tooltip').qtip('destroy', true);

	        lineup.hideLoader();

	        setTimeout(function()
	        {
		        lineup.closeLineupModal(200);

		        // lineup was updated
		        $(document).trigger('lineupUpdated');
	        }, 200);
        });
    };

    lineup.openLineupModal = function(lineupId)
    {
	    lineupId = (typeof lineupId !== 'undefined') ? lineupId : false;

        lineup.init();
        lineup.loadLineupElements(lineupId);
    };

    lineup.cancelLineup = function()
    {
        lineup.$_headerLineup
            .html(lineup.$activeLineupSaved.html())
            .attr('data-lineup', lineup.$activeLineupSaved.attr('data-lineup'));

        lineup.closeLineupModal();
    };

    lineup.closeLineupModal = function(speed)
    {
	    speed = (typeof speed != 'undefined') ? speed : 600;

        $('#lineup-edit').removeClass('-color-cancel').addClass('-color-yes');
	    main.$_modalLineup.find('.js-lineup-inner').html('');

        main.$_modalLineup.removeClass('-c-slide-down  js-modal-visible');

        setTimeout(function()
        {
            main.$_modalLineup.hide(1);
        }, speed);

        main.$_centercolOverlay.hide();

        lineup.$_headerLineup.removeClass();

        lineup.lineupModalOpen = false;
    };

    lineup.showLoader = function()
    {
        this.$_modalLineupOverlay.addClass('-c-active');
    };

    lineup.hideLoader = function()
    {
        this.$_modalLineupOverlay.removeClass('-c-active');
    };

    lineup.setMinHeight = function()
    {
        main.$_modalLineup.find('#lineup-cards').css({
            'min-height' : main.$_modalLineup.find('#lineup-cards').outerHeight()
        });
    };

    lineup.loadLineupElements = function(lineupId)
    {
        var sendData = {};

        lineup.showLoader();

        lineup.$activeLineupSaved = lineup.$_headerLineup.clone();

        if (lineupId !== false)
        {
	        lineup.activeLineup = lineupId;
        }
        else
        {
	        lineup.activeLineup = lineup.$_headerLineup.attr('data-lineup');
        }

        sendData['Lineup'] = lineup.activeLineup;

        $('#lineup-edit').removeClass('-color-yes').addClass('-color-cancel');

        main.$_centercolOverlay.show();
        main.$_modalLineup.find('.js-lineup-inner').html('');
        main.$_modalLineup.show(1).addClass('-c-slide-down  js-modal-visible');

        lineup.$_headerLineup.addClass('-status-editing');

        $.ajaxHandler({
            url: '/myteam/lineup',
            type: 'GET',
            data: sendData,
            dataType: 'json',
            cache: false
        }, {
            sourceElement: 'modal-lineup'
        }).done(function(data)
        {
            $.i18n.load(data['jsLang']);

            main.$_modalLineup.find('.js-lineup-inner').html(data['content']);

            if (lineupId !== false && typeof data['headerLineup'] !== 'undefined')
            {
	            lineup.$_headerLineup.find('.header-inside__lineup-inner').html(data['headerLineup']);
            }

            lineup.chakraUsage = data['lineupTeam']['Chakra']['Cur'];
            lineup.chakraMax = data['lineupTeam']['Chakra']['Max'];
            lineup.combos = data['combosJson'];
            lineup.combosData = data['combosDataJson'];
            lineup.ninjasData = data['ninjasDataJson'];

            lineup.hideLoader();

            lineup.updateCardSize();
            lineup.setMinHeight();

            lineup.refreshCombos();

            lineup.setLineupOffPositioning();
        });
    };

    lineup.loadLineup = function(lineupId)
    {
        var sendData = {};

        sendData['Lineup'] = lineupId;

        lineup.showLoader();

        $.ajaxHandler({
            url: '/myteam/lineup/' + lineupId,
            type: 'GET',
            dataType: 'json',
            cache: false
        }, {
            sourceElement: 'modal-lineup'
        }).done(function(data)
        {
            main.$_modalLineup.find('#lineup-cards').html(data['lineup']);
	        main.$_modalLineup.find('#lineup-extra-cards').html(data['lineupExtra']);
            main.$_modalLineup.find('.pm-lineup-chakra-box__nr b').html(data['lineupTeam']['Chakra']['Cur']);
	        main.$_modalLineup.find('#lineup-details').html(data['lineupTeamDetails']);

            lineup.$_headerLineup.find('.header-inside__lineup-inner').html(data['headerLineup']);

            lineup.activeLineup = lineupId;
            lineup.chakraUsage = data['lineupTeam']['Chakra']['Cur'];

            lineup.setChakraUsageHtml();

            lineup.updateCardSize();

            lineup.refreshCombos();

            lineup.setLineupOffPositioning();

            lineup.hideLoader();
        });
    };

}(window.lineup = window.lineup || {}, jQuery));
